<template>
  <v-main>
    <transition name="fade" mode="out-in">
      <div
        v-if="!brand"
        key="l_1"
        class="fill-height d-flex justify-center align-center login-intro"
      >
        <div class="d-flex flex-sm-row flex-column align-center">
          <v-img
            class="mb-10 mb-sm-0"
            :width="$vuetify.breakpoint.xsOnly ? '112px' : '132px'"
            :src="require('@/assets/images/wearelocals_people.svg')"
          />
          <div class="text-center mx-12 mb-sm-0 mb-10">
            <h2 class="text-sm-h2 text-h4 font-weight-bold mb-2">Welcome!</h2>
            <p class="text-h4 mb-8">Please choose your brand to log in</p>
            <div class="d-flex flex-column align-center">
              <router-link
                :to="{
                  path: '/login',
                  query: next_url
                    ? { brand: 'wearelocals', next_url: next_url }
                    : { brand: 'wearelocals' },
                }"
              >
                <v-btn
                  :block="$vuetify.breakpoint.xsOnly"
                  class="btn-login-select text-h4 font-weight-medium mb-8"
                  outlined
                  rounded
                  color="#00237c"
                >
                  We Are Locals</v-btn
                >
              </router-link>
              <router-link
                :to="{
                  path: '/login',
                  query: next_url
                    ? { brand: 'foodiependent', next_url: next_url }
                    : { brand: 'foodiependent' },
                }"
              >
                <v-btn
                  :block="$vuetify.breakpoint.xsOnly"
                  class="btn-login-select text-h4 font-weight-medium"
                  outlined
                  rounded
                  color="foodie"
                >
                  Foodiependent</v-btn
                >
              </router-link>
            </div>
          </div>
          <div class="balloons-intro">
            <v-img
              class="taco"
              :width="$vuetify.breakpoint.smAndDown ? '71px' : '86px'"
              :src="require('@/assets/images/b_taco_red.svg')"
            />
            <v-img
              class="burger"
              :width="$vuetify.breakpoint.smAndDown ? '61px' : '71px'"
              :src="require('@/assets/images/b_burger_yellow.svg')"
            />
          </div>
        </div>
      </div>
      <div v-else key="l_2" class="login-wrapper" :class="brand">
        <div class="hero fill-height">
          <div
            class="d-flex flex-column align-center justify-center fill-height"
            :class="brand"
          >
            <v-img
              contain
              class="flex-grow-0"
              :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
              :src="brand_config[brand].logo"
            />
          </div>
        </div>
        <div
          class="login d-flex flex-column align-center justify-center"
          :class="brand"
        >
          <transition name="fade" mode="out-in">
            <div
              v-if="!firebase_mode && !resetting_password"
              key="r_1"
              class="login-form px-4"
            >
              <div
                class="
                  align-center align-sm-bottom
                  d-flex
                  flex-sm-row flex-column
                  justify-sm-space-between
                "
              >
                <h3
                  class="
                    order-2 order-sm-1
                    align-self-sm-end
                    text-sm-h1 text-h2
                    font-weight-bold
                    pb-10
                    text-center text-md-left
                    mt-md-0
                    pt-4
                  "
                  :class="{ 'mt-n4': brand === 'foodiependent' }"
                >
                  Welcome
                </h3>
                <div
                  v-if="brand == 'foodiependent'"
                  class="
                    position-relative
                    order-1 order-sm-2
                    flex-grow-1
                    full-width
                  "
                >
                  <div
                    class="
                      balloons-foodie
                      justify-space-between
                      d-flex
                      ma-10
                      ml-16
                    "
                  >
                    <v-img
                      class="flex-grow-0 align-self-end"
                      :width="$vuetify.breakpoint.smAndDown ? '71px' : '86px'"
                      :src="require('@/assets/images/b_taco_blue.svg')"
                    />
                    <v-img
                      class="flex-grow-0 align-self-start"
                      :width="$vuetify.breakpoint.smAndDown ? '61px' : '71px'"
                      :src="require('@/assets/images/b_burger_blue.svg')"
                    />
                  </div>
                </div>
              </div>
              <v-form v-model="form_valid" ref="form">
                <v-text-field
                  class="mb-2"
                  outlined
                  label="Email"
                  color="#00237c"
                  v-model="email"
                  :rules="rules_email"
                  required
                />
                <v-text-field
                  outlined
                  label="Password"
                  color="#00237c"
                  hide-details
                  v-model="password"
                  :rules="rules_password"
                  :append-icon="
                    !show_password ? 'visibility' : 'visibility_off'
                  "
                  @click:append="() => (show_password = !show_password)"
                  @keyup.enter="submit()"
                  :type="!show_password ? 'password' : 'text'"
                  required
                />
                <div>
                  <v-checkbox
                    class="d-inline-block"
                    color="foodie"
                    v-model="remember_me"
                    label="Remember Me"
                  ></v-checkbox>
                </div>
                <v-expand-transition>
                  <v-alert
                    v-if="error"
                    dense
                    text
                    class="fade-in"
                    type="error"
                    >{{ error }}</v-alert
                  >
                </v-expand-transition>
                <div class="d-flex justify-space-between align-center">
                  <a
                    text
                    class="d-flex align-center mr-4"
                    color="foodie"
                    @click="$router.go(-1)"
                  >
                    <v-icon small color="foodie">mdi-arrow-left-bold</v-icon>
                    <span class="mt-1 ml-1">Back</span>
                  </a>
                  <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                  <a
                    class="mr-4 foodie--text font-weight-bold"
                    @click="resetting_password = true"
                    >Forgot password</a
                  >
                  <v-btn
                    rounded
                    large
                    color="foodie"
                    class="btn-login font-weight-bold mr-0"
                    @click="submit"
                    :disabled="!form_valid || is_login_loading"
                  >
                    <v-progress-circular
                      v-if="is_login_loading"
                      :size="20"
                      indeterminate
                      color="white"
                    />
                    <span v-else class="white--text">Login</span>
                  </v-btn>
                </div>
              </v-form>
              <p class="text-right font-weight-medium mb-2 mt-6">
                For support, visit our help library
                <a
                  class="foodie--text font-weight-bold"
                  target="_blank"
                  :href="brand_config[brand].support_url"
                >
                  here</a
                >.
              </p>
              <p class="text-right mb-2">
                Any problems, please email
                <a
                  class="foodie--text font-weight-bold"
                  :mailto="brand_config[brand].email"
                  >{{ brand_config[brand].email }}</a
                >
              </p>
            </div>
            <div
              v-else-if="resetting_password"
              key="r_2"
              outlined
              class="px-4 py-12"
              style="min-width: 80%"
            >
              <h3
                class="
                  text-h2
                  font-weight-bold
                  text-center text-md-left
                  mt-md-0 mt-n8
                "
              >
                Reset your password
              </h3>
              <p class="pb-4">
                Enter your email and we will send you a reset password link
              </p>
              <v-alert
                v-if="reset_success"
                type="success"
                border="left"
                colored-border
                class="mb-8"
              >
                We've sent you an email with a reset password link. Please check
                your email.
              </v-alert>
              <v-form v-model="reset_form_valid" ref="reset_form">
                <v-text-field
                  outlined
                  label="Email"
                  color="#00237c"
                  placeholder="Enter your email address"
                  v-model="email"
                  :rules="rules_email"
                  required
                />
                <div class="d-flex align-center justify-space-between">
                  <a
                    text
                    class="d-flex align-center"
                    color="foodie"
                    @click="
                      (reset_success = false), (resetting_password = false)
                    "
                  >
                    <v-icon small color="foodie">mdi-arrow-left-bold</v-icon>
                    <span class="mt-1 ml-1">Back</span>
                  </a>
                  <v-spacer />
                  <v-btn
                    rounded
                    color="foodie"
                    class="btn-login font-weight-bold mr-0"
                    @click.stop.prevent="resert_user_password"
                    :disabled="!reset_form_valid || is_login_loading"
                  >
                    <v-progress-circular
                      v-if="is_login_loading"
                      :size="20"
                      indeterminate
                      color="white"
                    />
                    <span v-else class="white--text">Reset</span>
                  </v-btn>
                </div>
              </v-form>
            </div>
            <FirebaseAuthModes
              v-else-if="firebase_mode"
              :mode="$route.query.mode"
              :action-code="$route.query.oobCode"
              :continue-url="$route.query.continueUrl"
              @go-to-forget-password="
                $router.replace('/login?brand=wearelocals'),
                  (resetting_password = true)
              "
            />
          </transition>
        </div>
      </div>
    </transition>
  </v-main>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import FirebaseAuthModes from "@/components/FirebaseAuthModes";

import { mapActions, mapState } from "vuex";
import { field_req, valid_email } from "@/utils/form_val_rules";
import { reset_password } from "@/requests";

export default {
  name: "Login",
  data() {
    return {
      error: null,
      form_valid: false,
      reset_form_valid: false,
      resetting_password: false,
      reset_success: false,
      remember_me: false,
      email: null,
      password: null,
      show_password: false,
      rules_email: [field_req, valid_email],
      rules_password: [field_req],
      brand_config: {
        wearelocals: {
          logo: require("@/assets/images/wearelocals_logo_white.svg"),
          support_url: "http://support.wearelocals.co.uk",
          email: "hello@wearelocals.co.uk",
        },
        foodiependent: {
          logo: require("@/assets/images/foodiependent_logo_white.svg"),
          support_url: "https://support.wearefoodiependent.com",
          email: "hello@wearefoodiependent.com",
        },
      },
    };
  },
  components: {
    FirebaseAuthModes,
  },
  computed: {
    ...mapState("UserStore", ["user", "is_login_loading"]),
    ...mapState(["server_url"]),
    brand() {
      return "wearelocals";
    },
    firebase_mode() {
      return this.$route?.query?.mode ?? null;
    },
    next_url() {
      return this.$route?.query?.next_url ?? null;
    },
  },
  methods: {
    ...mapActions("UserStore", ["set_user", "set_login_loading"]),
    async resert_user_password() {
      this.set_login_loading(true);
      try {
        await reset_password({
          email: this.email,
          continue_url: window.location.origin,
        });
        this.email = null;
        this.reset_success = true;
        this.$refs.reset_form.resetValidation();
      } finally {
        this.set_login_loading(false);
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.set_login_loading(true);
      this.error = false;

      try {
        const login_persistence = this.remember_me
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION;
        // NB: set firebase.auth.Auth.Persistence.NONE for having
        // multiple tabs with different auths (e.g. multiple shops)
        await firebase.auth().setPersistence(login_persistence);

        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);

        // BEWARE: user_credentials.user.getIdTokenResult() won't have the
        // custom claims granted via Firebase Admin SDK
        const user = await firebase.auth().currentUser;

        this.set_user(user);
      } catch (e) {
        let msg;
        if (e.code === "auth/user-not-found") {
          msg = "Sorry, given details are incorrect.";
        } else if (e.code === "auth/wrong-password") {
          msg = "Sorry, given details are incorrect.";
        } else if (e.code === "api/login/business-not-found") {
          msg =
            "Sorry we could not find any business associated with your account. Please contact support.";
        } else {
          msg = "Unexpected error. Please contact support.";
        }
        this.error = msg;
      } finally {
        this.set_login_loading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.btn-login-select {
  height: 50px !important;
  border-width: 2px;
  text-transform: initial;
}

.btn-login {
  height: 50px !important;
  text-transform: initial;
}

.login-intro {
  @media #{ map-get($display-breakpoints, 'sm-and-down')} {
    background-image: url("~@/assets/images/wearelocals_bg_2.svg");
    background-position: center 5%;
    background-size: 160%;
  }
  @media #{ map-get($display-breakpoints, 'md-and-up')} {
    background-position: center;
    background-image: url("~@/assets/images/wearelocals_bg_1.svg");
    background-size: cover;
  }
}

// Balloon icons
.balloons-intro {
  display: grid;

  grid-template-columns: 60px 30px 60px;
  grid-template-rows: 1fr 1fr 1fr;

  gap: 0% 0%;
  grid-template-areas:
    ". . taco"
    ". . ."
    "burger . .";
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}
.taco {
  grid-area: taco;
}
.burger {
  grid-area: burger;
}

.balloons-foodie {
  position: absolute;
  bottom: -60px;
  right: 0;
  width: 60%;
  max-width: 480px;
  height: 20vh;
  min-height: 140px;

  @media #{ map-get($display-breakpoints, 'sm-and-down')} {
    bottom: -40px;
    left: -60px;
  }
}

.floating-balloons {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

  > * {
    position: absolute;
    pointer-events: none;
  }
  .b_1 {
    top: 260px;
    right: 75%;
    width: 66px;
    @media #{ map-get($display-breakpoints, 'md-and-up')} {
      top: 30%;
      right: 15%;
    }
  }
  .b_2 {
    top: 200px;
    right: 38%;
    width: 80px;
    @media #{ map-get($display-breakpoints, 'md-and-up')} {
      top: 20%;
      right: 8%;
    }
  }
}

.login-wrapper {
  height: 100%;
  display: grid;
  gap: 0% 0%;
  grid-template-areas: "." ".";
  justify-content: center;
  align-content: start;
  justify-items: center;
  grid-auto-rows: max-content;
  align-content: normal;

  grid-template-columns: 1fr;

  &.wearelocals {
    grid-template-rows: 300px auto;
    background-color: #00237c;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      grid-template-columns: 50% 50%;
      align-items: center;
      grid-template-rows: 1fr;
    }
  }
  &.foodiependent {
    grid-template-rows: 300px auto;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      align-items: center;
      grid-template-columns: 55% 45%;
      grid-template-rows: 1fr;
    }
  }
}

.hero {
  width: 100%;

  .wearelocals {
    background-color: #00237c;
    background-image: url("~@/assets/images/wearelocals_people_pin.svg");
    background-position: -80px center;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      background-position: -20px center;
      background-size: 100px;
    }
  }
  .foodiependent {
    background-color: linear-gradient(90deg, #46c2db 50%, #bee9f2);
    background-image: url("~@/assets/images/foodiependent_login_bg.jpg");
    background-position: right 70%;
    background-size: cover;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-bottom: 30%;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-right: 200px;
    }
  }
}
.login {
  background: #ffffff;
  position: relative;
  z-index: 1;
  width: 100%;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 100%;
  }

  &.foodiependent {
    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      z-index: -1;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        bottom: 99%;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjM4NSAtMjMxLjkgNDE0IDE1NS42IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDM4NSAtMjMxLjkgNDE0IDE1NS42OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNzc1LjMtOTYuNWMtMTUuMy01LjEtMjguNS0xNi40LTQwLjItMzEuNGMtNi45LTguOC0xMi45LTE4LjktMTkuOS0yNy40Yy0zNi45LTQ0LjYtNzguOS03MS0xMjYuNS03Ng0KCWMtMjMuOC0yLjUtNDYuNCw0LjQtNjcuNiwyMS4zYy0yMS40LDE3LjEtNDMuOSwzMC45LTY3LjQsNDAuN2MtMjIuNiw5LjQtNDUuNCwxNC43LTY4LjcsMTIuNXY4MC41aDQxNFYtOTQNCglDNzkxLjEtOTMsNzgzLTkzLjksNzc1LjMtOTYuNXoiLz4NCjwvc3ZnPg0K);
        background-position: 0 100%;
      }
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        background-position: 100% 0;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjYwIC0xOC45IDEzMyA2ODYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgNjAgLTE4LjkgMTMzIDY4NjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcwLjYtMTguOWMxLjEsMS4zLDIuMywyLjYsMy40LDRjMTguMiwyMS4xLDM3LDQxLjgsNTMuNCw2NC4xYzI5LjksNDAuNiwzNi45LDg1LjUsMTcuNSwxMzMuOQ0KCWMtOC4xLDIwLjItMTcuOSwzOS41LTMzLjYsNTYuNGMtNy44LDguNC0xNC42LDE3LjUtMjIuMSwyNi4yQzYwLDMwMCw1NC44LDMzNy4zLDY0LjMsMzc3YzUuOSwyNC43LDE2LjgsNDcuMSwzMS4yLDY4LjMNCgljOSwxMy4yLDE3LjcsMjYuNywyNC43LDQwLjljMTUuOSwzMi40LDE5LjksNjcuNSwyMS4zLDEwMy4yYzEsMjYuNi01LjUsNTIuMi0xNC44LDc3LjhIMTkzdi02ODZINzAuNnoiLz4NCjwvc3ZnPg0K);
        right: 99%;
      }
    }
  }
}

.login-form {
  width: 90%;
  margin: 0 auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 750px;
  }
}

::v-deep .v-text-field--outlined {
  border-radius: 12px;

  fieldset {
    border-width: 2px;
  }
}
.login-card {
  border: 3px solid #00237c;
  border-radius: 24px;
}
</style>
