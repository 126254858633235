<template>
  <div class="action-wrapper">
    <p class="text-h2 font-weight-bold">
      {{ success ? "All done!" : title }}
    </p>
    <v-alert v-if="error" type="error" border="left" colored-border>
      <p>{{ error_msg }}</p>
      <a
        v-if="mode === 'verifyEmail'"
        class="font-weight-bold"
        :href="continueUrl"
        target="_blank"
        >Login</a
      >
      <a
        v-if="mode === 'resetPassword'"
        class="font-weight-bold"
        @click="$emit('go-to-forget-password')"
        >Reset password</a
      >
    </v-alert>
    <v-alert v-if="success" type="success" border="left" colored-border>
      <p>{{ success_message }}</p>
      <a class="font-weight-bold" :href="continueUrl" target="_blank">Login</a>
    </v-alert>
    <v-form v-model="form_valid" :disabled="loading" v-if="!error && !success">
      <v-text-field
        id="new-password"
        v-model="new_password"
        :type="show_pw ? 'text' : 'password'"
        label="New password"
        class="required mb-4"
        color="#00237c"
        hint="Password must be at least 8 characters long"
        outlined
        :rules="[field_req, valid_password]"
      />
      <v-text-field
        id="new-password-confirm"
        v-model="confirm_password"
        :type="show_pw ? 'text' : 'password'"
        label="Confirm password"
        color="#00237c"
        class="required"
        outlined
        :rules="[
          field_req,
          valid_password,
          () => new_password === confirm_password || 'Passwords do not match',
        ]"
      />
      <v-checkbox v-model="show_pw" label="Show password" />
      <div class="text-right">
        <v-btn
          color="primary"
          class="mr-0 justify-self-end"
          @click="handle_reset_password"
          rounded
          :disabled="!form_valid || loading"
          :loading="loading"
          >Submit</v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { field_req, valid_password } from "@/utils/form_val_rules";

export default {
  name: "FirebaseAuthModes",
  props: {
    mode: { type: String, required: true },
    actionCode: { type: String, required: true },
    continueUrl: { type: String, required: true },
  },
  data() {
    return {
      error: false,
      success: false,
      success_message: null,
      field_req,
      valid_password,
      account_email: null,
      loading: true,
      show_pw: false,
      form_valid: false,
      new_password: null,
      confirm_password: null,
    };
  },
  computed: {
    title() {
      if (this.mode === "resetPassword") return "Setup your new password";
      else if (this.mode === "verifyEmail") return "Verify your email";
      return "Action Required";
    },
    error_msg() {
      if (this.mode === "resetPassword")
        return `Sorry, it seems that your verification code has expired or
         has already been used. Please try to reset your password again by 
         clicking the link below.`;
      else if (this.mode === "verifyEmail")
        return `Sorry, it seems that your verification code has expired or
         has already been used. Please try login again so we can send you 
         new verification link.`;
      return "Something went wrong. Please try again or contact support.";
    },
  },
  async mounted() {
    const auth = firebase.auth();
    let email;
    try {
      switch (this.mode) {
        case "resetPassword":
          email = await auth.verifyPasswordResetCode(this.actionCode);
          this.account_email = email;
          break;
        case "verifyEmail":
          await this.handle_verify_email();
          break;
        default:
          break;
      }
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async handle_reset_password() {
      try {
        this.loading = true;
        const auth = firebase.auth();
        await auth.confirmPasswordReset(this.actionCode, this.new_password);
        await auth.signOut();
        let continue_url = new URL(this.continueUrl);

        if (continue_url.origin === window.location.origin) {
          await auth.signInWithEmailAndPassword(
            this.account_email,
            this.new_password
          );
        } else {
          this.success = true;
          this.success_message =
            "We successfully reset your password. You can login now.";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async handle_verify_email() {
      try {
        this.loading = true;
        const auth = firebase.auth();
        await auth.applyActionCode(this.actionCode);
        await auth.signOut();
        this.success = true;
        this.success_message =
          "We successfully verified your email. You can login now.";
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-wrapper {
  width: 80%;
}
</style>
